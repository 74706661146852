import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { ISeo } from '../models/seo';
import { IGlobal } from '../models/global';

interface SeoProps {
  seo: ISeo;
}

export const Seo: React.FC<SeoProps> = ({ seo = {} }) => {
  const {
    strapiGlobal,
  }: {
    strapiGlobal: IGlobal;
  } = useStaticQuery(query);
  const { defaultSeo, siteName, favicon } = strapiGlobal;

  // Merge default and page-specific SEO values
  const fullSeo = { ...defaultSeo, ...seo };

  const getMetaTags = () => {
    interface MetaTag {
      property?: string;
      name?: string;
      content: string;
    }
    const tags: MetaTag[] = [
      {
        name: 'viewport',
        content:
          'width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0',
      },
    ];

    if (fullSeo.metaTitle) {
      tags.push(
        {
          property: 'og:title',
          content: fullSeo.metaTitle,
        },
        {
          name: 'twitter:title',
          content: fullSeo.metaTitle,
        }
      );
    }
    if (fullSeo.metaDescription) {
      tags.push(
        {
          name: 'description',
          content: fullSeo.metaDescription,
        },
        {
          property: 'og:description',
          content: fullSeo.metaDescription,
        },
        {
          name: 'twitter:description',
          content: fullSeo.metaDescription,
        }
      );
    }
    if (fullSeo.shareImage) {
      const imageUrl = process.env.GATSBY_STRAPI_URL + fullSeo.shareImage.url;
      tags.push(
        {
          name: 'image',
          content: imageUrl,
        },
        {
          property: 'og:image',
          content: imageUrl,
        },
        {
          name: 'twitter:image',
          content: imageUrl,
        }
      );
    }
    if (fullSeo.article) {
      tags.push({
        property: 'og:type',
        content: 'article',
      });
    }
    tags.push({ name: 'twitter:card', content: 'summary_large_image' });

    return tags;
  };

  const metaTags = getMetaTags();

  return (
    <Helmet
      title={fullSeo.metaTitle}
      titleTemplate={`%s | ${siteName}`}
      link={[
        {
          rel: 'icon',
          href: favicon.url,
        },
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/css?family=Montserrat:bold,boldi,normal,normali,600,600i,500,500i',
        },
      ]}
      meta={metaTags}
    />
  );
};

const query = graphql`
  query {
    strapiGlobal {
      siteName
      favicon {
        url
      }
      defaultSeo {
        metaTitle
        metaDescription
        shareImage {
          url
        }
      }
    }
  }
`;
